import { mapHelper } from 'common/utils'

// 状态
const status = [
  {
    text: '是',
    value: 1
  },
  {
    text: '否',
    value: 0
  }
]

const {
  map: statusMap,
  setOps: setStatusOps
} = mapHelper.setMap(status)

// 类型
const appType = [
  {
    text: '支付宝',
    value: 1
  },
  {
    text: '微信',
    value: 2
  }
]

const {
  map: appTypeMap,
  setOps: setAppTypeOps
} = mapHelper.setMap(appType)

export {
  statusMap,
  setStatusOps,
  appTypeMap,
  setAppTypeOps
}
