// 获取列表
const getListURL = `${API_CONFIG.butlerBaseURL}subjectCommonShu/listSubjectsGrantedCommunityMicroapp`
// 项目应用关联小程序
const relateURL = `${API_CONFIG.butlerBaseURL}community/microapp/relation/subjectMicroapp`
// 查询平台产品列表
const getProductListURL = `${API_CONFIG.butlerBaseURL}community/microapp/list/microAppInfo`
// 获取平台列表
const queryCommunityListURL = `${API_CONFIG.butlerBaseURL}SysMicroAppInfo/getMicroAppInfoListByPage`
export {
  getListURL,
  relateURL,
  getProductListURL,
  queryCommunityListURL
}
