<template>
  <div class="AppInfoConfigList-wrapper">
    <list
      ref="list"
      :searchUrl="searchUrl"
      :searchParams.sync="searchParams"
      :headers="headers"
      :isMultiSelect="true"
      :hasOperateColumn="false"
    >
      <template #headerSlot>
        <v-button text="批量配置" @click="grantApplication" />
      </template>
      <template #searchSlot>
        <v-select2 placeholder="输入项目名称" label="所属项目" v-model="searchParams.communityId" v-bind="communityParams" :subjoin="communitySubjoin" @onChange="communityChange"></v-select2>
        <v-select2 placeholder="输入公司名称" label="所属公司" v-model="searchParams.regionId" v-bind="regionParams"></v-select2>
        <v-input label="应用名称" v-model="searchParams.subName" :maxlength="5" />
        <v-select label="所属类别" v-model="searchParams.subHandle" :options="subHandleOps" />
        <v-select label="所属版块" v-model="searchParams.sectionId" :options="sectionOps" optionGroup />
        <v-select label="应用类型" v-model="searchParams.subType" :options="subTypeOps" />
        <v-select label="应用状态" v-model="searchParams.subStatus" :options="statusOps" />
        <v-datepicker label="创建时间段" type="rangedatetimer" :maxDate="new Date()" :startTime.sync="searchParams.inTimeFrom" :endTime.sync="searchParams.inTimeTo" />
      </template>
      <template #batchSlot="scope">
        <v-button text="批量配置" :disabled="scope.selectedData.ids.length === 0" @click="batch(scope.selectedData)"></v-button>
      </template>
    </list>
    <el-dialog :visible.sync="visible" title="小程序应用配置">
      <div style="text-align: left; margin-bottom: 20px;">
        请选择应用支持的三方平台产品<br>
        （注：不选状态下确认，会删除应用支持三方平台产品下的所有内容）
      </div>
      <div class="check-box-wrapper">
        <div class="check-item" v-for="(item, index) in productList" :key="index">
          <v-checkbox v-model="item.checked" />
          <span>{{item.name}}</span>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <v-button type="default" @click="cancel" style="margin-right: 20px">取 消</v-button>
        <v-button type="primary" @click="comfirm">确 定</v-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { communityParams, regionParams } from 'common/select2Params'
import { createImgVNode } from 'common/vdom'
import { getListURL, relateURL, getProductListURL } from './api'
import { appTypeMap } from './map'
import { subHandleMap, setSubHandleOps, subTypeMap, setSubTypeOps, statusMap, setStatusOps } from 'views/application/applicationLibrary/map'
import { getSectionOps } from 'views/application/applicationLibrary/common'
import { vCheckbox } from 'components/control'

export default {
  name: 'AppInfoConfigList',
  components: {
    vCheckbox
  },
  data () {
    return {
      searchUrl: getListURL,
      relateURL,
      subHandleOps: setSubHandleOps(1),
      subTypeOps: setSubTypeOps(1),
      statusOps: setStatusOps(1),
      communityParams,
      regionParams,
      searchParams: {
        communityId: undefined,
        regionId: undefined,
        subName: '',
        subHandle: undefined,
        sectionId: undefined,
        subType: undefined,
        subStatus: undefined
      },
      headers: [
        {
          prop: 'subImg',
          label: '应用图标',
          formatter: (row, prop) => createImgVNode(this, row, prop)
        },
        {
          prop: 'subName',
          label: '应用名称'
        },
        {
          prop: 'communityName',
          label: '所属项目'
        },
        {
          prop: 'subHandleText',
          label: '所属类别',
          formatter: row => subHandleMap[row.subHandle]
        },
        {
          prop: 'sectionName',
          label: '所属版块'
        },
        {
          prop: 'subTypeText',
          label: '应用类型',
          formatter: row => subTypeMap[row.subType]
        },
        {
          prop: 'subSort',
          label: '应用排序'
        },
        {
          prop: 'subStatusText',
          label: '应用状态',
          formatter: row => statusMap[row.subStatus]
        },
        {
          prop: 'pauseServiceTime',
          label: '应用暂停时间'
        },
        {
          prop: 'inTime',
          label: '创建时间'
        },
        {
          prop: 'microappNames',
          label: '支持第三方平台产品'
        }
      ],
      sectionOps: [],
      visible: false,
      productList: [],
      subCommunityIds: [],
      selecteds: []
    }
  },
  computed: {
    userType () {
      return this.$store.state.userInfo.userType
    },
    communitySubjoin () {
      return {
        regionId: this.searchParams.regionId
      }
    }
  },
  created () {
    this.getProduct()
  },
  methods: {
    async communityChange (community, isBack) {
      if (this.userType !== '101') {
        if (community && community.id) {
          this.sectionOps = await getSectionOps({
            params: {
              communityId: community.id
            }
          })
        } else {
          this.resetSectionOps()
        }
        if (!isBack) {
          this.searchParams.sectionId = undefined
        }
      }
    },
    async relate () {
      let microappLists = this.productList.filter(item => item.checked).map(({ appId, name, appType }) => ({ appId, appName: name, appType }))

      // 操作日志添加操作对象参数 规则：小程序名称-应用名称-所属项目
      let dataObject = `(${microappLists.map(item => item.appName).join(',')}):`
      this.selecteds.forEach(item => {
        dataObject += `${item.subName}-${item.communityName};`
      })
      dataObject = dataObject.substring(0, dataObject.length - 1)
      let params = {
        subCommunityIds: this.subCommunityIds,
        microappList: microappLists,
        dataObject: dataObject
      }
      await this.$axios.post(relateURL, params)
      this.visible = false
      this.$refs.list.searchData()
    },
    batch (selected) {
      this.visible = true
      this.subCommunityIds = selected.data.map(item => item.subCommunityId)
      this.selecteds = selected.data.map(item => {
        return {
          subName: item.subName,
          communityName: item.communityName
        }
      })

      this.productList.forEach(item => { item.checked = false }) // 初始化产品构选
    },
    create () {
      this.$router.push({
        name: 'appInfoConfigForm'
      })
    },
    edit (row) {
      this.$router.push({
        name: 'appInfoConfigForm',
        query: {
          id: row.id
        }
      })
    },
    async getProduct () {
      let { data, status } = await this.$axios.get(getProductListURL)
      if (status === 100) {
        this.productList = data.map(item => ({ ...item, name: `${appTypeMap[item.appType]}-${item.name}`, checked: false }))
      }
    },
    comfirm () {
      this.relate()
    },
    cancel () {
      this.visible = false
    },
    grantApplication () {
      this.$router.push({
        name: 'AppInfoConfigForm'
      })
    },
  }
}
</script>
<style lang="scss" scoped>
  .check-box-wrapper {
    display: flex;
    flex-wrap: wrap;
  }
  .check-item {
    display: flex;
    margin-right: 20px;
    margin-bottom: 20px;
    &:last-child {
      margin-right: 0px;
    }
    span {
      white-space: nowrap;
    }
  }
</style>
